import { Injectable, signal } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ChatWidgetService {
  public chatVisible = signal<'maximized' | 'minimized' | 'hidden' | undefined>(
    'hidden',
  );

  public openChatWidget(): void {
    this.chatVisible.set('maximized');
  }

  public handleVisibilityChanged(event: {
    visibility: 'maximized' | 'minimized' | 'hidden';
  }): void {
    this.chatVisible.set(event.visibility);
  }
}
